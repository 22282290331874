.v-line {
  border-left : solid #5FC;
  height : 35px;
  margin-bottom: 10px;
  display: inline-flex;

  @media (max-width: 767px) {
    display: none;
  }
}
