$nav-height: 64px; //TODO: 향후 NavBar 관리 영역으로 분리 필요

.home-item {
  height: 100%;
  padding-top: $nav-height * 2;
  position: relative;

  @media (max-width: 767px) {
    padding-top: $nav-height;
  }
}

.data-infuser {
  color: #fff;
  background: var(--Data-Infuser, linear-gradient(117deg, #131212 3.43%, #151010 60.13%, #75160e 131.1%));

  .data-infuser-logo {
    aspect-ratio: 280 / 162;
    max-height: 162px;
    max-width: 280px;

    @media (max-width: 767px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.doc-infuser {
  color: #fff;
  background: linear-gradient(184deg, #070a1a 7.47%, #151a33 28.55%, #0c1d3e 46.82%, #151a33 62.13%, #070a1a 98.48%);

  .doc-infuser-logo {
    aspect-ratio: 392 / 118;
    max-height: 118px;
    max-width: 392px;
    margin-bottom: 88px;

    @media (max-width: 767px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.datamap {
  color: #fff;
  background-size: cover;

  .datamap-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: bottom right;
    background-size: 66%;
    background-repeat: no-repeat;
  }
}

.infuser-features {
  padding-top: 200px;

  @media (max-width: 767px) {
    padding-top: 2rem;
    text-align: center;
  }

  .infuser-feature {
    min-width: 400px;
    height: 100px;
    border: 1px solid #fff;
    border-radius: 50px;
    margin-bottom: 24px;
    display: inline-block;

    @media (max-width: 767px) {
      min-width: 20rem;
      max-width: 25rem;
      height: 60px;
      width: 50%;
      margin-bottom: 10px;
    }

    .infuser-feature-icon {
      text-align: center;
      font-size: 32px;
      float: left;
      width: 80px;
      height: 80px;
      line-height: 80px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      margin: 10px;

      @media (max-width: 767px) {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 1.5rem;
      }
    }

    .infuser-feature-text {
      width: calc(100% - 120px);
      font-size: 24px;
      font-weight: bold;
      float: left;
      height: 100px;
      line-height: 100px;
      vertical-align: middle;
      text-align: center;

      @media (max-width: 767px) {
        width: calc(100% - 4.5rem);
        height: 3.8rem;
        font-size: 1.2rem;
        line-height: 3.8rem;
      }
    }
  }
}

.home-sub-nav {
  z-index: 90;
  position: fixed;
  margin: 0;
  top: $nav-height * 2;
  font-size: 22px;
  font-weight: 600;
  padding-inline-start: 20px;
  color: rgba(255, 255, 255, 0.3);

  > li {
    cursor: pointer;

    &:hover {
      color: #f2f2f2;
    }
  }

  > li.active {
    color: #fff;
    font-size: 28px;

    &::marker {
      font-size: 22px;
      color: red;
    }
  }
}

.qufa-arch-wrap {
  text-align: right;
  align-content: center;
  @media (max-width: 767px) {
    text-align: center;
  }
  .qufa-arch-img {
    @media (max-width: 767px) {
      width: 80%;
    }
    width: 100%;
    max-width: 720px;
    padding-top: $nav-height;
  }
}

.qufa-ex-wrap {
  text-align: center;
  align-content: center;
  .qufa-ex-img {
    // aspect-ratio: 60/78;
    // margin-left: 40px;
    height: 100%;
    max-height: 600px;
  }
}

.qufa-desc-wrap {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  line-height: 24px;
  // margin-left: 35px;

  @media (max-width: 767px) {
    justify-content: center;
    margin-left: 0;
    margin-top: 1rem;

    .m-desc-border {
      border: 1px solid #fff;
      border-radius: 50px;
      min-width: 20rem;
      min-height: 5rem;
      align-content: center;
    }
  }
}

.divider-wrap {
  display: flex;
  .divider {
    background-color: #55ffcc;
  }
}

.bigdata {
  color: #fff;
  .bigdata-title {
    font-weight: 700;
  }

  .bigdata-desc {
    font-weight: 400;
    line-height: 25px;
  }
}

@keyframes moveUpDown {
  0% {
    top: 4px;
  }
  50% {
    top: 12px;
  }
  100% {
    top: 4px;
  }
}

.scroll-down-wrap {
  position: fixed;
  bottom: 48px;
  color: #fff;
  width: 100%;

  &.hide {
    display: none;
  }

  &.scroll-center {
    text-align: center;
  }

  &.text-gray {
    color: #a6a9ac;
  }

  .scroll-down-arrow {
    display: none;
  }

  &.first-page {
    .scroll-down-arrow {
      display: block;
    }

    .scroll-icon-box {
      display: none !important;
    }
  }

  .scroll-box {
    width: 112px;
    text-align: center;
    display: inline-block;

    .scroll-icon-box {
      position: relative;
      margin-top: 8px;
      display: inline-block;
      width: 20px;
      height: 28px;
      border: 2px solid #1577f2;
      border-radius: 10px;

      .scroll-bullet {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #52ff58;
        display: inline-block;
        top: 4px;
        left: 4px;

        -webkit-animation: moveUpDown 0.8s infinite;
        animation: moveUpDown 0.8s infinite;
      }
    }
  }
}
