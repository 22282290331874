@import "./animation";

.scroll-down-icon {
  position: fixed;
  margin-left: 80px;
  text-align: center;
  z-index: 99;
  animation-name: scrlDwnIndc;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.start-double-quotation {
  text-align: start;
  margin-right: 100px;
  height: 40px;
}

.end-double-quotation {
  margin-left: 100px;
  height: 40px;
}
