  .modal-root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: flex-start; // 상단 정렬 유지
    justify-content: center;
    padding-top: 5vh;
    background: rgba(0, 0, 0, 0.7); // 반투명 검은색
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    @media (max-width: 600px) {
      align-items: center; // 모바일 가독성 향상
    }
  }

  .modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;    
  }

  // 모달 전체 레이아웃 감싸는 틀
  .modalWrapper {
    display: flex;
    flex-direction: column;
    width: 480px;
    max-height: 80vh; // 화면 크기에 맞춰 최대 높이 제한
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    @media (max-width: 600px) { // 모바일용 스타일
      width: 90vw; // 화면 너비의 90%로 조정
      max-height: 80vh; // 높이도 조금 줄여서 더 보기 좋게
      overflow-y: auto;
    }
    @media (max-width: 350px) {
      width: 90vw; // 화면 너비의 90%로 조정
      max-height: 90vh; // 높이도 조금 줄여서 더 보기 좋게
      overflow-y: auto;
    }
  }
  
  
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between; // 양쪽 정렬
    padding: 10px 15px;
    background: #D33B32;
    color: #fff;
    font-size: 1.3rem;
    // border-bottom: 1px solid #ddd;
    // border-radius: 15px 15px 0 0;
  
    .logo {
      height: 30px;
      width: auto;
      @media (max-width: 350px) {
        height: 20px !important; // 모바일 가독성 향상
      }
    }
  
    .modalTitle {
      flex-grow: 1; // 가운데 정렬 느낌
      text-align: center;
      font-weight: bold;
    }
  
    .closeButton {
      cursor: pointer;
      font-size: 1.5rem;
      color: #fff;
      background: transparent;
      border: none;
  
      &:hover {
        color: #ddd;
      }
    }
    @media (max-width: 600px) {
      font-size: 1.1rem !important; // 모바일 가독성 향상
    }
    @media (max-width: 350px) {
      font-size: 0.8rem !important; // 모바일 가독성 향상
      padding: 0px 5px !important;
    }
  }

  .baseFont {
    font-size: 0.8rem !important;
    @media (max-width: 600px) {
      font-size: 0.65rem !important; // 모바일 가독성 향상
    }
    @media (max-width: 350px) {
      font-size: 0.6rem !important; // 모바일 가독성 향상
    }
  }


  .modalBody {
    padding: 20px 25px 0px 25px;
    @extend .baseFont;
    @media (max-width: 350px) {
      padding: 10px 12px 0px 12px !important;
    }
  }

  .modalFooter {
    @extend .baseFont;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .mb-1 {
    margin-bottom: 10px;
  }
  


  .contentTable {
    &:first-child {
      border-top: 1px solid #ddd;
      padding-top: 20px;
    }
    td {
      @extend .baseFont;
      border-bottom: none;
      padding: 5px;
      vertical-align: top;
      &:first-child {
        width: 30%; // 2 비율
        font-weight: bold;
      }
  
      &:last-child {
        width: 70%; // 10 비율
        text-align: left;
      }
    }
  }

  .contentHead {
    @extend .baseFont;
    font-weight: bold;
  //   text-align: center;
  }
  
  .contentFoot {
    @extend .baseFont;
    padding: 20px;
    text-align: center;
    @media (max-width: 6000px) {
      padding: 12px !important; // 모바일 가독성 향상
    }
    @media (max-width: 350px) {
      padding: 7px !important; // 모바일 가독성 향상
    }
  }

  .contentDescription {
    @extend .baseFont;
    padding-top : 20px;
    @media (max-width: 350px) {
      padding-top : 5px !important; // 모바일 가독성 향상
    }
  }

  .button {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #38484B;
    color: #fff;
    &:hover {
      background-color: #4A5A5E; 
    }
  }


  body.modal-open {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  touch-action: none; // 모바일 스크롤 방지
}

 
  
  
  
  