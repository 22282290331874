.carousel {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.slider-container {
  width: 100%;
  div button:before {
    color: red;
  }
}
