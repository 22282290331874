.main-color {
  color: #26272f;
}

.main-red-title {
  color: #b8313d;
}

.white {
  color: #fff;
}
