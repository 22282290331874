@keyframes scrlDwnIndc {
  0%    {bottom: 80px;}
  20%   {bottom: 84px;}
  40%   {bottom: 88px;}
  60%   {bottom: 92px;}
  80%   {bottom: 94px;}
  100%  {bottom: 84px;}
}

.hide {
  display: none;
}