.feature-item {
  position: relative;
  aspect-ratio: 63 / 36;
  background-size: cover;
  margin-right: 16px;
  padding: 72px 48px;
  color: #fff;

  &:before {
    background-color: rgba(0, 0, 0, 0.47);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    margin: 0 1vh;
    padding: 30px 16px;
  }

  .feature-content-wrap {
    position: relative;
    height: 100%;

    .title {
      font-size: 32px;
      font-weight: bold;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    .content {
      font-size: 20px;
      position: absolute;
      bottom: 0;
      text-shadow: 1px 1px #888;
      word-break: keep-all;

      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
  }
}
