.word-26 {
  font-size: 26px;
}

.word-20 {
  font-size: 20px;
}

.word-49 {
  font-size: 49px;
  font-weight: 600;
}

.word-41 {
  font-size: 41px;
  font-weight: 600;
}

.word-47 {
  font-size: 47px;
  font-weight: 600;
}

.fw-4 {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.bold-word {
  font-size: 30px;
  font-weight: 700;
  color: #b8313d;
}

.bold-word-48 {
  font-size: 34px;
  font-weight: 700;
  color: #b8313d;
}

.bold-word-20 {
  color: #b8313d;
  font-size: 20px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 38px;
}

.ul-desc {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
}
