.certificate-box {
  &:hover {
    .content-item {
      opacity: 0;
      z-index: 0;
    }

    .patent-divider {
      opacity: 0;
      z-index: 0;
    }

    .content-image {
      opacity: 1;
      z-index: 1;
    }
  }
}
