@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');

@import 'home';
@import 'components/color';
@import 'patent';
@import 'patent-plain';
@import 'certificate';
@import 'certificate-modal';
@import 'history';
@import 'product';
@import 'company';
@import 'meeting_modal';
@import 'components/top_banner';
@import 'components/carousel';
@import 'components/feature';
@import 'components/icon';
@import 'components/line';
@import 'components/word';
@import 'components/box';
@import 'components/footer';
@import 'components/banner';

.text-right {
  text-align: right;
}

@media (max-width: 767px) {
  .m-text-center {
    text-align: center;
  }
}

.t-bold {
  font-weight: bold;
}

.t-18 {
  font-size: 18px;
}

.t-20 {
  font-size: 20px;
}

.t-22 {
  font-size: 22px;
}

.t-24 {
  font-size: 24px;
}

.t-28 {
  font-size: 28px;
}

.t-40 {
  font-size: 40px;
}

.t-48 {
  font-size: 48px;
}

.t-61 {
  font-size: 60px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pt-20-p {
  padding-top: 20%;
}

.pt-30-p {
  padding-top: 30%;
}

.pt-64 {
  padding-top: 64px;
}

.pt-0 {
  padding-top: 0;
}

.pt-100 {
  padding-top: 100px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-200 {
  padding-bottom: 200px;
}

.p1-20 {
  padding-left: 20px;
}

@media (max-width: 767px) {
  .m-pt-0 {
    padding-top: 0;
  }

  .m-pt-100 {
    padding-top: 100;
  }

  .m-pt-rem-1 {
    padding-top: 1rem;
  }

  .m-pt-rem-2 {
    padding-top: 2rem;
  }

  .m-pb-0 {
    padding-bottom: 0;
  }

  .m-pb-100 {
    padding-bottom: 100px;
  }

  .m-pb-rem-1 {
    padding-bottom: 1rem;
  }

  .m-pb-rem-2 {
    padding-bottom: 2rem;
  }

  .m-pt-20-p {
    padding-top: 20%;
  }
}

.block-xs {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.block-sm {
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    display: none;
  }
}

.d-inline {
  display: inline;
}

.mobile-d-none {
  @media (max-width: 900px) {
    display: none;
  }
}

.mobile-d-none-600 {
  @media (max-width: 600px) {
    display: none;
  }
}

.mobile-d-none-767 {
  @media (max-width: 767px) {
    display: none;
  }
}
