.patent-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    position: relative;
  }
}
