.patent-plain-container {  
  position: relative;
  padding-top: 240px;

  @media (min-width: 1200px) {
    max-width: 1920px !important;
  }

  &::before {
    content: 'PATENT';
    position: absolute;
    top: 12%;
    left: 20%;
    transform: translate(-50%, -50%);
    font-size: 10rem;
    color: rgba(0, 0, 0, 0.1);
    z-index: -1;
    white-space: nowrap;

    @media (max-width: 599px) {
      top: 3%;
      left: 28%;
      font-size: 5rem;
    }

    @media (min-width: 600px) and (max-width: 899px) {
      top: 6.5%;
      left: 24%;
      font-size: 7rem;
    }

    @media (min-width: 900px) and (max-width: 1199px) {
      top: 6.5%;
      left: 19%;
      font-size: 8rem;
    }

    @media (min-width: 1200px) {
      top: 12%;
      left: 20%;
    }
  }
}
