.nav-header {
  background-color: transparent;
  box-shadow: none;

  > a {
    font-weight: 400;
    font-size: 16px;
  }
}

.nav-link {
  font-size: 16px;
  color: #a8abb8;
  min-width: 140px;
}

.nav-link:hover {
  background-color: transparent;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.submenu-item {
  min-width: 140px;
  padding: 2px 10px;
  color: #a8abb8;
  text-decoration: none;
}

.submenu-item:hover {
  background-color: transparent;
}
