$box-line-width: 32px;

.img-bg-box {
  position: relative;

  .bg-item {
    position: absolute;
    width: calc(100% - $box-line-width);
    height: calc(100% - $box-line-width);
    background: #b8313d;
    z-index: -1;
  }

  img {
    position: relative;
    top: $box-line-width;
    left: $box-line-width;
    width: calc(100% - $box-line-width);
    height: calc(100% - $box-line-width);
  }
}

.img-icon-box {
  display: flex;
  width: 90px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #b8313d;
  background-position: top;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-evenly;
  img {
    // position: relative;
    // top: 0px;
  }
}
