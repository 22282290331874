.title {
  font-weight: 500;
  font-size: 54px;
  color: white;
  align-content: end;
  line-height: 70px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    font-size: 1.5rem;
  }

  .bold {
    font-size: 64px;
    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  
    @media (min-width: 600px) and (max-width: 1199px) {
      font-size: 2rem;
    }
  }
}

.sub {
  font-weight: 500;
  font-size: 18px;
  padding-top: 45px;
  color: white;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 2rem;
  }

  .bold {
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
}

.solution-carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: end;
  padding-top: 196px;
  @media (max-width: 768px) {
    padding-top: 240px;
  }
  
  @media (max-width: 600px) {
    display: none;
  }
}

.solution-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0 0 0;
}

.solution-background {
  z-index: -1;
  width: 100%;
  padding-top: 64px;
  height: 100%;
  position: absolute;

  @media (max-width: 768px) {
    padding: 64px 0;
  }
}

.desc {
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  line-height: 48px;
  padding-top: 12px;

  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
  }
}

.desc-bold {
  font-weight: 700;
  font-size: 54px;
  color: #b8313d;

  @media (max-width: 768px) {
    font-weight: 600;
    font-size: 28px;
  }
}

.drag-disable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.solution-svg {
  color: #252429;
  opacity: 0.2;
  width: 40px;
  height: 30px;

  @media (max-width: 768px) {
    width: 24px;
    height: 16px;
  }
}

.qufa-studio-title {
  height: 40px;
  font-size: 42px;
  font-weight: 600;
  line-height: 40px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .bold {
    font-size: 60px;
    font-weight: 700;
  
    @media (max-width: 768px) {
      font-size: 3rem;
      font-weight: 700;
    }
  }
}

.qufa-studio-title-gradient {
  background: linear-gradient(#75FFCD, #A9FAE7);
  -webkit-background-clip: text;
  background-clip: text;
-webkit-text-fill-color: transparent;
}