.banner-typo {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: end;
  left: 0;
  top: 60px;
  position: absolute;
  @media (max-width: 768px) {
    width: 60%;
  }
}
