.footer {
  padding-top: 24px;
  padding-bottom: 24px;

  @media (max-width: 599px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }


  .footer-text-wrap {
    .footer-name-text {
      text-align: right;
      font-size: 22px;
      font-weight: bold;
      color: #FFFFFF;
  
      @media (max-width: 599px) {
        font-size: 18px;
      }
    
      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 18px;
      }
    
      @media (min-width: 1200px) {
        font-size: 22px;
      }
    }
    .footer-address-text {
      text-align: right;
      font-size: 14px;
      color: #FFFFFF;
  
      @media (max-width: 599px) {
        font-size: 10px;
      }
    
      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 10px;
      }
    
      @media (min-width: 1200px) {
        font-size: 14px;
      }
    }
    .footer-copyright-text {
      text-align: right;
      font-size: 13px;
      color: #A2A2A2;
  
      @media (max-width: 599px) {
        font-size: 9px;
      }
    
      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 9px;
      }
    
      @media (min-width: 1200px) {
        font-size: 13px;
      }
    }
    
  }
  .footer-image-wrap {
    text-align: left;
    @media (max-width: 599px) {
      text-align: right;
    }
  
    @media (min-width: 600px) and (max-width: 1199px) {
      text-align: right;
    }
  
    .footer-image {
      height: 90px;
      @media (max-width: 599px) {
        height: 60px;
        margin-right: -20px;
      }
    
      @media (min-width: 600px) and (max-width: 1199px) {
        height: 60px;
      }
    
      @media (min-width: 1200px) {
        height: 90px;
      }
    }
  }
  
}
