.top-banner {
  height: 480px;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 64px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: end;

  .product-intro {
    
    .title {
      padding-top: 128px;
      font-size: 70px;
      margin-bottom: 8px;
    }
    .desc {
      margin-top: 0;
      font-size: 24px;
      padding-bottom: 53px;
    }
  }
}
