.product-container {
  max-width: 100% !important;
  padding: 0;

  margin-top: 0px;

  .header-box {
    text-align: center;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url(../assets/product/data-infuser-bg.png);
    background-position: center;
    height: 480px;
    max-height: 5000px;
    max-width: 100%;
    // opacity: 0.9;
    background-repeat: no-repeat;

    .header-product {
      color: white;
      font-size: 8rem;
      font-weight: bold;
      opacity: 0.08;
      text-align: left;
      line-height: 7rem;
      margin-top: 200px;

      @media (max-width: 599px) {
        font-size: 5rem;
      }

      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 10rem;
      }

      @media (min-width: 1200px) {
        font-size: 10rem;
      }
    }

    .header-title {
      color: white;
      font-size: 62px;
      font-weight: bold;
      position: absolute;
      bottom: 70px;
      right: 10px;
      margin-right: 100px;

      @media (max-width: 599px) {
        font-size: 54px;
      }

      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 54px;
      }

      @media (min-width: 1200px) {
        font-size: 62px;
      }
    }

    .header-subtitle {
      margin-right: 100px;
      margin-top: 1rem;
      font-size: 24px;
      color: white;
      min-width: 10px;
      position: absolute;
      bottom: 15px;
      right: 10px;

      @media (max-width: 599px) {
        font-size: 20px;
      }

      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 24px;
      }

      @media (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }

  .product-content-box {
    text-align: left;
    .infuser-desc-title {
      height: 50px;
      width: 320px;
      font-weight: bold;
    }
  }
}

.header-box {
  text-align: center;
  margin-bottom: 40px;
}

.header-product {
  font-weight: bold;
  line-height: 1.2;
}

.header-title {
  font-size: 2rem;
  margin-top: 20px;
}

.header-subtitle {
  font-size: 1.5rem;
  color: #757575;
}

.product-content-box {
  margin-top: 40px;
}

.infuser-desc-grid {
  align-items: center;
  margin-bottom: 100px;
}

.infuser-desc-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 60px;
}

.infuser-desc-sub {
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 10px;
  display: inline;

  &.bold-word {
    font-size: 24px;
    font-weight: 700;
    color: #b8313d;
  }
}

.infuser-desc-img-box {
  width: 280px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.content-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #000;
  text-align: center;
  font-size: 1rem;
  margin: 0 auto;
}

.content-detail-title {
  font-size: 1rem;
  text-align: center;
}

.infuser-desc-img {
  width: 100%;
}

.infuser-logo-img {
  max-width: 280px;
}

.infuser-desc-box {
  // padding-left: 100px;
}

.product-infuser-desc-header-box {
  width: 100%;
  text-align: center;
  font-size: 47px;
  font-style: normal;
  font-weight: 600;
  line-height: 65px;
  margin-bottom: 100px;
}

.data-infuser-manager {
  width: 100%;
  // border: 3px solid #ededf4;
}

.data-infuser-desc2 {
  width: -webkit-fill-available;
}

.product-infuser-character-header-box {
  color: var(--main-text, #26272f);
  font-family: Pretendard;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-bottom: 45;
}

.product-infuser-char-box {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 380px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.product-infuser-desc-box {
  background-size: cover;
  font-size: 20px;
  color: #26272f;
  font-weight: 400;
  width: 100%;
  height: 380px;
  margin-bottom: 16px;

  align-items: center;
  .title {
    padding: 8px;
    text-align: left;
    color: #26272f;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.white-color-title {
  color: #fff;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.qufa-desc-row {
}

.qufa-desc-box {
}

.qufa-desc-grid {
  align-items: center;
  margin-bottom: 80px;
}

.qufa-desc-title {
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 40px;
}

.qufa-desc-sub {
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 10px;
  display: inline;

  .bold-word {
    font-size: 30px;
    font-weight: 700;
  }
}

.qufa-content-title {
  color: #206f52;
  font-weight: 700;
  font-size: 60px;
  text-align: center;
}

.qufa-content {
  width: 90%;
  padding-top: 50px;
}

.qufa-content-diff {
  width: 70%;
  padding-top: 10%;
}

.box1 {
  background-image: url(../assets/product/char1.png);
}

.box2 {
  background-image: url(../assets/product/char2.png);
}

.box3 {
  background-image: url(../assets/product/char3.png);
}

.box4 {
  background-image: url(../assets/product/char4.png);
}

.product-section {
  padding: 48px 0;
}

.big-data-bg-img {
  width: 100%;
}

.data-map-container {
  p,
  span,
  div {
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  @media (max-width: 900px) {
    padding: 32px;
  }
}

.data-map-double-quote {
  &.start {
    margin-right: 60px;
    @media (min-width: 0px) {
      margin-right: 10px;
    }
    @media (min-width: 600px) {
      margin-right: 15px;
    }
    @media (min-width: 900px) {
      margin-right: 20px;
    }
  }
  &.end {
    margin-left: 60px;
    @media (min-width: 0px) {
      margin-left: 10px;
    }
    @media (min-width: 600px) {
      margin-left: 15px;
    }
    @media (min-width: 900px) {
      margin-left: 20px;
    }
  }
}
