$arrow-size: 48px;
$dot-position: 48px;

.slick-slider {
  margin-bottom: $dot-position + 24px;

  @media (max-width: 767px) {
    .slick-track {
      display: flex;
    }
  }
  
}

.slick-prev {
  left: -1 * ($arrow-size / 2);

  &::before {
    content: '<';
  }

  @media (max-width: 767px) {
    $arrow-size : 32px;
    left: -1 * ($arrow-size / 2);
  }
}

.slick-next {
  right: -1 * ($arrow-size / 2);

  &::before {
    content: '>';
  }

  @media (max-width: 767px) {
    $arrow-size : 32px;
    right: -1 * ($arrow-size / 2);
  }
}

.slick-prev,
.slick-next {
  width: $arrow-size;
  height: $arrow-size;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  z-index: 999;
  box-shadow:
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid #ddd;

  &::before {
    opacity: 0.5;
    color: #adb7d5 !important;
  }

  &:hover {
    background-color: #fff;

    &::before {
      opacity: 1 !important;
    }
  }

  @media (max-width: 767px) {
    $arrow-size : 32px;
    width: $arrow-size;
    height: $arrow-size;
  }
}

.slick-dots {
  bottom: -1 * $dot-position;

  > li {
    width: 12%;
    margin: 0 1px 0 0;

    > button {
      background-color: #d2dadf;
      width: 100%;
      height: 3px;
      padding: 0;

      &:before {
        content: '*';
      }
    }

    &.slick-active {
      > button {
        background-color: #b8313d;
      }
    }
  }
}
