.company-desc-img {
  width: 100%;
}

.company-desc-inner {
  justify-content: space-between;
  text-align: justify;
  height: 100%;
  display: flex;
}

.company-desc-sub {
  color: #26272f;
  font-family: Pretendard;
  font-weight: 400;
  line-height: 46px;
  font-size: 30px;
}

.company-box1 {
  background-image: url(../assets/company/company_bg1.png);
}

.company-box2 {
  background-image: url(../assets/company/company_bg2.png);
}

.company-box3 {
  background-image: url(../assets/company/company_bg3.png);
}

.company-promise-box {
  background-image: url(../assets/imgs/companies/company_backgraound.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 1080px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 599px) {
    .backgroundBox {
      height: 300px;
    }
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    .backgroundBox {
      height: 500px;
    }
  }

  @media (min-width: 1200px) {
    .backgroundBox {
      height: 1080px;
    }
  }
}
