.history-container {
  max-width: 100% !important;
  padding: 0;

  margin-top: 0px;

  .header-box {
    text-align: center;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url(../assets/history/history_image.png);
    background-position: center;
    height: 500px;
    max-height: 5000px;
    max-width: 100%;
    // opacity: 0.9;
    background-repeat: no-repeat;

    @media (max-width: 599px) {
      font-size: 5rem;
      height: 300px;
    }

    @media (min-width: 600px) and (max-width: 1199px) {
      font-size: 10rem;
      height: 400px;
    }

    @media (min-width: 1200px) {
      font-size: 16rem;
    }

    .header-history {
      color: white;
      font-size: 16rem;
      font-weight: bold;
      opacity: 0.08;
      text-align: left;

      @media (max-width: 599px) {
        font-size: 5rem;
      }

      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 10rem;
      }

      @media (min-width: 1200px) {
        font-size: 16rem;
      }
    }

    .header-title {
      color: white;
      font-size: 62px;
      font-weight: bold;
      position: absolute;
      bottom: 70px;
      right: 10px;
      margin-right: 100px;

      @media (max-width: 599px) {
        font-size: 54px;
        margin-right: 10px;
      }

      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 54px;
      }

      @media (min-width: 1200px) {
        font-size: 62px;
      }
    }

    .header-subtitle {
      margin-right: 100px;
      margin-top: 1rem;
      font-size: 24px;
      color: white;
      min-width: 10px;
      position: absolute;
      bottom: 15px;
      right: 10px;

      @media (max-width: 599px) {
        font-size: 20px;
        margin-right: 10px;
      }

      @media (min-width: 600px) and (max-width: 1199px) {
        font-size: 24px;
      }

      @media (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }

  .history-content-box {
    margin-bottom: 90px;
    .history-grid-container {
      .year-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 42px;
        background: var(--1, linear-gradient(92deg, #121f38 1.56%, #1f3e76 100.17%));
        font-weight: 700;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-top: 20px;
        margin-bottom: 4px;
        position: relative;
      }

      .patent-divider {
        border-color: #bbbdcc;
        margin-top: 28px;
        margin-bottom: 28px;
      }

      .content-box {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        text-align: right;

        .month-box {
          font-size: 20px;
          font-weight: 800;
          line-height: 1;

          color: #b8313d;
          @media screen and (max-width: 599px) {
            margin-right: 0px;
          }
          align-self: flex-start;
        }

        .content-detail-box {
          font-size: 18px;
          font-weight: 400;
          color: #26272f;
          flex: 1;
          line-height: 1.6;
          @media (max-width: 599px) {
            font-size: 14px;
          }
    
          @media (min-width: 600px) and (max-width: 1199px) {
            font-size: 14px;
          }
    
          @media (min-width: 1200px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.timeline {
  padding: 0;

  &.mobile {
    padding: 0;
  }
}
