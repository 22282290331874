.patent-container {
  padding-top: 240px;
  @media (max-width: 599px) {
    padding-top: 120px;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    padding-top: 180px;
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    padding-top: 240px;
  }

  @media (min-width: 1200px) {
    padding-top: 240px;
  }

  &::before {
    content: 'CERTIFICATE';
    position: absolute;
    top: 22%;
    left: 33%;
    transform: translate(-50%, -50%);
    font-size: 10rem;
    color: rgba(0, 0, 0, 0.1);
    z-index: -1;
    white-space: nowrap;

    @media (max-width: 599px) {
      top: 6.7%;
      left: 44%;
      font-size: 5rem;
    }

    @media (min-width: 600px) and (max-width: 899px) {
      top: 12%;
      left: 38%;
      font-size: 7rem;
    }

    @media (min-width: 900px) and (max-width: 1199px) {
      top: 20%;
      left: 33%;
      font-size: 8rem;
    }

    @media (min-width: 1200px) {
      top: 22%;
      left: 33%;
    }
  }

  @media (min-width: 1200px) {
    max-width: 1920px;
  }
}

.patent-box {
  height: 320px;
  // width: 400px;
  padding: 40px;
  margin-bottom: 10px;
  border: 3px solid #ededf4;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: opacity 0.5s ease;
  background-color: white;

  .content-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease;
  }

  .content-item {
    opacity: 1;
    z-index: 1;
  }

  .content-image {
    opacity: 0;
    z-index: 0;

    img {
      max-height: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .title-box {
    width: 100%;
    min-height: 50px;
  }

  .title-text {
    margin: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;

    @media (max-width: 599px) {
      font-size: 1rem;
    }

    @media (min-width: 600px) and (max-width: 1199px) {
      font-size: 1rem;
    }

    @media (min-width: 1200px) {
      font-size: 1.1rem;
    }
  }

  .patent-divider {
    border-color: #b8313d;
    margin: 10px 0;
  }

  .content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 599px) {
      font-size: 1rem;
    }

    @media (min-width: 600px) and (max-width: 1199px) {
      font-size: 0.75rem;
    }

    @media (min-width: 1200px) {
      font-size: 1rem;
    }

    .content-item {
      background-color: #ffffff;
      padding-left: 0;
      // margin-top: 10px;
      border-radius: 4px;
      text-align: left;
      font-size: 18px;
      line-height: 34px;
      max-height: 4em;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: opacity 0.5s ease;
    }

    .content-item.small-font {
      font-size: 0.75rem;
    }
  }
}
